<template>
  <div>
    <ul class="share">
      <li v-for="(icon, index) in icons" :key="index" @click="show(index)">
        <el-image v-if="index != 2" :src="icon"></el-image>


        <el-popover
            v-else
            placement="left"
            trigger="click"
            width="160"
            style="height: 160px;">
          <div style="margin: auto; text-align: center;">
            <vue-qr :text="url" :size="160"></vue-qr>
          </div>
          <el-image slot="reference" :src="icon"></el-image>
        </el-popover>
      </li>
    </ul>
    <!--<el-dialog-->
    <!--    :visible.sync="dialogVisible"-->
    <!--    width="70%">-->
    <!--    <div style="margin: auto; text-align: center;">-->
    <!--      <vue-qr :text="url" :size="200"></vue-qr>-->
    <!--    </div>-->
    <!--</el-dialog>-->
  </div>
</template>

<script>
import vueQr from 'vue-qr';

export default {
  components: {
    vueQr,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: window.location.href
    },
  },
  data() {
    return {
      dialogVisible: false,
      icons: [
        require('@/assets/images/share/lianjie.png'),
        require('@/assets/images/share/weibo.png'),
        require('@/assets/images/share/weixin.png'),
        require('@/assets/images/share/youxiang.png'),
      ],
    }
  },
  methods: {
    show(index) {
      let url = this.url;
      switch (index) {
        case 0:
          debugger
          this.$copyText(url).then(e => {
            this.$message.success('复制成功！')
          }, e => {
            this.$message.error('复制失败！')
          })
          break;
        case 1:
          let weibo = 'https://service.weibo.com/share/share.php?url=' + url + '&pic=' + this.image + '&title=' + this.title;
          window.open(weibo, "_blank")
          break;
        case 2:
          this.dialogVisible = true
          break;
        case 3:
          let href = 'mailto:wyang@chinagoldensun.cn';
          location.href = href;
          // window.h(href, "_blank")
          break;
      }
    }
  }
}
</script>

<style lang="less">
.share{
  position: fixed;
  top: 30%;
  right: calc((100% - 1200px) / 2 - 100px);
  li{
    width: 30px;
    height: 30px;
    margin-top: 20px;
  }
}
</style>